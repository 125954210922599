import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { getLucaCompanies } from '../../service/request/companyRequest';

import Loading from '../module/datasoft/components/Loading';

export default function SearchLucaComp() {
    const [globalFilter, setGlobalFilter] = useState(null);
    const [companyList, setCompanyList] = useState([]);
    const [taxNumber, setTaxNumber] = useState(null);
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    const getCompanies = async () => {
        setLoading(true);
        try {
            const response = await getLucaCompanies({ vkn: taxNumber });
            if (response.status === 200) {
                setCompanyList(response.data.data);
                setLoading(false);
                setTimeout(() => {
                    toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirketler başarıyla getirildi.', life: 3000 });
                }, 1);
            }
        } catch (error) {
            setLoading(false);
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: error.response.data.message, life: 3000 });
            }, 1);
        }
        setLoading(false);
        setExpandedRows(null);
    };

    if (loading) {
        return (
            <div style={{ height: '100vh' }}>
                <Loading />
            </div>
        );
    }

    const formatDate = (inputDateTime) => {
        const dateTimeObj = new Date(inputDateTime);

        const formattedDateTime = dateTimeObj.toLocaleString('tr-TR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        return formattedDateTime;
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between py-3">
            <div className="flex gap-8">
                <h3 className="m-0 text-white">Şirketler</h3>
            </div>
            <div className="flex gap-5">
                <div className="flex gap-5">
                    <div className="flex-column flex relative">
                        <label htmlFor="taxNumber" className="absolute bottom-100 text-white mb-1">
                            Vergi Numarası
                        </label>
                        <InputText value={taxNumber} label="Vergi Numarası" placeholder="Vergi Numarası Giriniz" className="w-20rem" onChange={(e) => setTaxNumber(e.target.value)} />
                    </div>

                    <Button label="Getir" icon="pi pi-arrow-right" className="w-8rem text-white" severity="success" onClick={() => getCompanies()} />
                </div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const rowExpansionTemplate = (rowData) => {
        return (
            <div className="p-3">
                <h5>{rowData.name} Modülleri</h5>
                <DataTable emptyMessage="Modül Bulunamadı" value={rowData.modules}>
                    <Column field="name" header="Modül" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="program" header="Program" style={{ minWidth: '12rem' }} sortable></Column>
                    <Column field="created_at" header="Oluşturulma Tarihi" body={(rowData) => formatDate(rowData.created_at)} style={{ minWidth: '12rem' }} sortable></Column>
                </DataTable>
            </div>
        );
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="card">
                <DataTable
                    ref={dt}
                    value={companyList}
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} şirketten {first} ila {last} arası gösteriliyor"
                    globalFilter={globalFilter}
                    header={header}
                    emptyMessage="Şirket Bulunamadı"
                >
                    <Column field="company" header="Şirket Adı" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="lucaName" header="Luca Adı" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="lucaPeriod" header="Luca Periyot" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="webService" header="Web Servis" sortable style={{ minWidth: '16rem' }}></Column>
                </DataTable>
            </div>
        </div>
    );
}
