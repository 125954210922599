import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import { loginAsyncThunk } from '../store/auth/authSlice';

import { useThemeContext } from '../context/useThemeContext';

export const Login = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(true);

    const { colorScheme } = useThemeContext();

    const toast = useRef(null);
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.auth);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const control = await dispatch(loginAsyncThunk({ email, password }));

        if (control.type === 'auth/login/rejected') {
            if (control.error.message.includes('Unauthorized')) {
                toast.current.show({ severity: 'error', summary: 'Giriş Başarısız', detail: 'Yetkisiz kullanıcı.' });
            } else {
                toast.current.show({ severity: 'error', summary: 'Giriş Başarısız', detail: 'E-Mail veya Şifre Hatalı.' });
            }
        }
    };

    return (
        <div className="login-body">
            <Toast ref={toast} position="top-right" />
            <div className="login-image">
                <img src={`assets/layout/images/pages/login-${colorScheme === 'light' ? 'ondark' : 'onlight'}.png`} alt="atlantis" />
            </div>
            <div className="login-panel p-fluid">
                <form className="flex flex-column" onSubmit={handleSubmit}>
                    <div className="flex align-items-center mb-6 logo-container">
                        <img src={`assets/layout/images/i-uyum-logo-${colorScheme === 'light' ? 'light' : 'dark'}.png`} className="login-appname w-10rem h-3rem" alt="login-appname" />
                    </div>
                    <div className="form-container">
                        <span className="p-input-icon-left">
                            <i className="pi pi-envelope"></i>
                            <InputText value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="E-mail" />
                        </span>
                        <div className="p-input-icon-left relative">
                            <i className="pi pi-key"></i>
                            <InputText value={password} onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'password' : 'text'} placeholder="Şifre" />
                            <Button
                                type="button"
                                className="p-button-text p-button-sm p-0 text-sky-600 ml-2 absolute top-0 h-full"
                                style={{ left: '20rem' }}
                                icon={showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        </div>
                    </div>
                    <div className="button-container mt-4">
                        <Button type="submit" label="Giriş Yap" loading={loading}></Button>
                    </div>
                </form>
            </div>
        </div>
    );
};
