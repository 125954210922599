import { createUser, setRoleFromUser, getByEmail } from '../../../service/request/userRequest';
import { createCompany, attachUser, attachModule } from '../../../service/request/companyRequest';
import { setCompanySettings } from '../../../service/request/companyRequest';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useState, useEffect, useRef } from 'react';
import { LucaRequest } from '../luca/utils/lucaRequest';
import { getCompanyForDropdown, getPeriodForDropdown } from '../luca/utils/util';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import DocumentCode from '../../../assets/data/DocumentCode.json';
import { getUyumTaxId } from '../../../service/request/companyRequest';
import { Toast } from 'primereact/toast';
import { getAllWebServicesByModuleId } from '../../../service/request/moduleRequest';

export default function UyumsoftComp() {
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        roleId: 11
    });
    const [company, setCompany] = useState({
        name: '',
        taxNumber: '',
        taxOffice: '',
        email: '',
        phone: '',
        address: ''
    });

    const [programReq, setProgramReq] = useState({
        username: '',
        password: '',
        customerNumber: ''
    });
    const [response, setResponse] = useState(null);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isLoginLuca, setIsLoginLuca] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [periodList, setPeriodList] = useState([]);
    const [selectedCode, setSelectedCode] = useState(null);
    const [newCode, setNewCode] = useState('');
    const [codeList, setCodeList] = useState([]);
    const toast = useRef(null);
    const [docCodeDialogVisible, setDocCodeDialogVisible] = useState(false);
    const [eLedgerComp, setELedgerComp] = useState('');
    const [eLedgerCompCode, setELedgerCompCode] = useState('');
    const [taxNum, setTaxNum] = useState('');
    const [lucaCompanies, setLucaCompanies] = useState([]);
    const [vcknResponse, setVcknResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [webServicesList, setWebServicesList] = useState([]);
    const [selectedWebService, setSelectedWebService] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [controlVckn, setControlVckn] = useState('');

    useEffect(() => {
        const getWebServices = async () => {
            const res = await getAllWebServicesByModuleId(2);
            setWebServicesList(res.data.data.filter((item) => item.visible === true));
        };
        getWebServices();
    }, []);

    useEffect(() => {
        if (user.name) {
            let labeledName = user.name + ' SMMM';
            setCompany({ ...company, name: labeledName });
        }
    }, [user.name]);

    const onInputChange = (e, name) => {
        const val = e.target.value;
        let _user = { ...user };
        _user[`${name}`] = val;
        setUser(_user);
    };

    const onCompanyInputChange = (e, name) => {
        const val = e.target.value;
        let _company = { ...company };
        _company[`${name}`] = val;
        setCompany(_company);
    };

    const onProgramInputChange = (e, name) => {
        const val = e.target.value;
        let _programReq = { ...programReq };
        _programReq[`${name}`] = val;
        setProgramReq(_programReq);
    };

    const loginLuca = async () => {
        setLoading(true);
        try {
            const res = await LucaRequest.login(programReq.username, programReq.password, programReq.customerNumber);
            setResponse(res);
            setCompanyList(getCompanyForDropdown(res));
            setIsLoginLuca(true);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message, life: 1500 });
        }
        setLoading(false);
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            const companyRes = await createCompany(company);
            const userRes = await createUser(user);
            await setRoleFromUser({ userId: userRes.data.data.id, roleId: 11 });
            await attachUser({ companyId: [companyRes.data.data.id], userId: userRes.data.data.id });
            await attachModule({ companyId: companyRes.data.data.id, moduleId: 2 });
            const data = {
                moduleId: 2,
                companyId: companyRes.data.data.id,
                settings: {
                    moduleId: 2,
                    title: 'E-Defter',
                    program: {
                        _id: 1,
                        title: 'Luca E-Defter',
                        requirements: [
                            {
                                username: programReq.username,
                                password: programReq.password,
                                customerNumber: programReq.customerNumber
                            }
                        ],
                        lucaCompanies: lucaCompanies
                    }
                }
            };

            await setCompanySettings(data);

            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Eklendi', life: 1500 });
            }, 100);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message, life: 1500 });
        }
        setLoading(false);
    };

    const handleCreateCompany = () => {
        const company = response.find((item) => item.company.value === selectedCompany);
        const period = company.periods.find((item) => item.value === selectedPeriod);
        const webServiceObj = webServicesList.find((item) => item.id === selectedWebService);

        const data = {
            companyName: company.company.label,
            companyCode: company.company.value,
            periodId: String(period.value),
            periodTitle: String(period.label),
            eDefterSubeAdi: eLedgerComp,
            eDefterSubeKodu: eLedgerCompCode,
            taxId: taxNum,
            belgeTurleri: codeList,
            webService: {
                _id: webServiceObj.id,
                title: webServiceObj.title,
                url: webServiceObj.url,
                requirements: {
                    username: username,
                    password: password
                }
            }
        };

        setLucaCompanies([...lucaCompanies, data]);
        setCompanyList(companyList.filter((item) => item.value !== selectedCompany));
        setDialogVisible(false);
        setSelectedCompany(null);
        setSelectedPeriod(null);
        setPeriodList(null);
        setELedgerComp('');
        setELedgerCompCode('');
        setTaxNum('');
        setVcknResponse(null);
        setUsername('');
        setPassword('');
        setSelectedWebService(null);
        setCodeList([]);
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Şirketler</h4>
            <div>
                <Button label="Şirket Ekle" icon="pi pi-plus-circle" className="p-button-primary text-white mr-4 mb-0" onClick={() => setDialogVisible(true)} disabled={!isLoginLuca} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const actionCodeTemplate = (rowData) => {
        return <Button icon="pi pi-trash" tooltip="Sil" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} className="mx-2" rounded outlined severity="danger" onClick={() => handleDeleteCompany(rowData)} />;
    };

    const dialogFooterTemplate = () => {
        return <Button label="Oluştur" icon="pi pi-check" className="p-button-info mt-3" onClick={() => handleCreateCompany()} disabled={handleCheckCompDisabled()} />;
    };

    const handleCheckCompDisabled = () => {
        return !selectedCompany || !selectedPeriod || !taxNum || !selectedWebService || !username || !password;
    };

    const handleGetVckn = async () => {
        setLoading(true);
        try {
            const res = await getUyumTaxId(taxNum);
            setVcknResponse(res.data.data);
            setUsername(res.data.data.webservice_username);
            setPassword(res.data.data.webservice_password);
            setSelectedWebService(res.data.data.webservice.id);
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Vergi Numarası Bulunamadı', life: 1500 });
        }
        setLoading(false);
    };

    const handleChangeCompany = async (e) => {
        setSelectedCompany(e.value);
        setPeriodList(getPeriodForDropdown(response, e.value));
    };

    const handleAddCode = () => {
        setCodeList([...codeList, { code: selectedCode, enum: newCode }]);
        setNewCode('');
        setSelectedCode(null);
    };

    const actionDocCodeTemplate = (rowData) => {
        return <Button icon="pi pi-trash" tooltip="Sil" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} className="mx-2" rounded outlined severity="danger" onClick={() => handleDeleteCode(rowData)} />;
    };

    const handleDeleteCode = (code) => {
        setCodeList(codeList.filter((item) => item.code !== code.code));
    };

    const dialogDocCodeFooterTemplate = () => {
        return <Button label="Tamam" icon="pi pi-check" onClick={() => setDocCodeDialogVisible(false)} />;
    };

    const handleDeleteCompany = (rowData) => {
        setLucaCompanies(lucaCompanies.filter((item) => item.companyCode !== rowData.companyCode));
        setCompanyList([...companyList, { title: rowData.companyName, value: rowData.companyCode }]);
    };

    const handleAllCheckDisabled = () => {
        return !isLoginLuca || lucaCompanies.length === 0 || !user.name || !user.email || !user.password || !company.name || !company.taxNumber || !programReq.username || !programReq.password || !programReq.customerNumber;
    };

    const handleProgramReqDisabled = () => {
        return !programReq.username || !programReq.password || !programReq.customerNumber;
    };

    const handleCheckEmail = async () => {
        try {
            const res = await getByEmail(user.email);
            if (res.data.data) {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Bu E-Mail Adresi Kullanılmaktadır', life: 1500 });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        } catch (error) {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Bu E-Mail Adresi Kullanılabilir', life: 1500 });
        }
    };

    const handleCheckVckn = async () => {
        try {
            const res = await getUyumTaxId(controlVckn);
            if (res.data.data) {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Vergi Numarası Mevcut', life: 1500 });
            }
        } catch (error) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Vergi Numarası Bulunamadı', life: 1500 });
        }
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="card">
                <h4>Mali Müşavir Kullanıcı Bilgileri</h4>
                <div className="flex gap-4">
                    <div className="field flex-1">
                        <label htmlFor="Kullanıcı Adı" className="font-bold block">
                            Kullanıcı Adı
                        </label>
                        <InputText className="w-full" id="name" value={user.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus placeholder="Kullanıcı Adı" />
                    </div>
                    <div className="field flex-1">
                        <label htmlFor="E-Mail" className="font-bold block">
                            E-Mail
                        </label>
                        <div className="flex align-items-center gap-3">
                            <InputText className="w-full" id="E-Mail" value={user.email} onChange={(e) => onInputChange(e, 'email')} required placeholder="E-Mail" />
                            <Button label="Kontrol Et" className="flex-shrink-0" onClick={() => handleCheckEmail()} disabled={!user.email} />
                        </div>
                    </div>
                    <div className="field flex-1">
                        <label htmlFor="Şifre" className="font-bold block">
                            Şifre
                        </label>
                        <InputText className="w-full" id="Şifre" value={user.password} onChange={(e) => onInputChange(e, 'password')} required placeholder="Şifre" />
                    </div>
                </div>
                <div className="field flex align-items-end gap-3 flex-1">
                    <div className="w-full flex flex-column gap-2">
                        <label htmlFor="taxNum" className="font-bold block">
                            Vergi Numarası
                        </label>
                        <InputText className="w-full" id="taxNum" value={controlVckn} onChange={(e) => setControlVckn(e.target.value)} required placeholder="Vergi Numarası" />
                    </div>
                    <Button className="flex-shrink-0" onClick={() => handleCheckVckn()} disabled={!controlVckn || controlVckn.length < 10 || controlVckn.length > 11} loading={loading}>
                        Kontrol Et
                    </Button>
                </div>
            </div>
            <div className="card">
                <h4>Mali Müşavir Şirket Bilgileri</h4>
                <div className="flex gap-4">
                    <div className="field flex-1">
                        <label htmlFor="companyName" className="font-bold block">
                            Şirket Adı
                        </label>
                        <InputText className="w-full" id="companyName" value={company.name} onChange={(e) => onCompanyInputChange(e, 'name')} required autoFocus placeholder="Şirket Adı" />
                    </div>
                    <div className="field flex-1">
                        <label htmlFor="taxNumber" className="font-bold block">
                            Vergi Numarası
                        </label>
                        <InputText className="w-full" id="taxNumber" value={company.taxNumber} onChange={(e) => onCompanyInputChange(e, 'taxNumber')} required placeholder="Vergi Numarası" />
                    </div>
                </div>
                <div className="flex gap-4">
                    <div className="field flex-1">
                        <label htmlFor="taxOffice" className="font-bold block">
                            Vergi Dairesi
                        </label>
                        <InputText className="w-full" id="taxOffice" value={company.taxOffice} onChange={(e) => onCompanyInputChange(e, 'taxOffice')} required placeholder="Vergi Dairesi" />
                    </div>
                    <div className="field flex-1">
                        <label htmlFor="email" className="font-bold block">
                            E-Mail
                        </label>
                        <InputText className="w-full" id="email" value={company.email} onChange={(e) => onCompanyInputChange(e, 'email')} required placeholder="E-Mail" />
                    </div>
                </div>
                <div className="flex gap-4">
                    <div className="field flex-1">
                        <label htmlFor="phone" className="font-bold block">
                            Telefon
                        </label>
                        <InputText className="w-full" id="phone" value={company.phone} onChange={(e) => onCompanyInputChange(e, 'phone')} required placeholder="Telefon" />
                    </div>
                    <div className="field flex-1">
                        <label htmlFor="address" className="font-bold block">
                            Adres
                        </label>
                        <InputText className="w-full" id="address" value={company.address} onChange={(e) => onCompanyInputChange(e, 'address')} required placeholder="Adres" />
                    </div>
                </div>
            </div>
            <div className="card">
                <h4>Luca Kullanıcı Bilgileri</h4>
                <div className="flex gap-4">
                    <div className="field flex-1">
                        <label htmlFor="username" className="font-bold block">
                            Kullanıcı Adı
                        </label>
                        <InputText className="w-full" id="username" value={programReq.username} onChange={(e) => onProgramInputChange(e, 'username')} required autoFocus placeholder="Kullanıcı Adı" />
                    </div>
                    <div className="field flex-1">
                        <label htmlFor="password" className="font-bold block">
                            Şifre
                        </label>
                        <InputText className="w-full" id="password" value={programReq.password} onChange={(e) => onProgramInputChange(e, 'password')} required placeholder="Şifre" />
                    </div>
                    <div className="field flex-1">
                        <label htmlFor="customerNumber" className="font-bold block">
                            Müşteri Numarası
                        </label>
                        <InputText className="w-full" id="customerNumber" value={programReq.customerNumber} onChange={(e) => onProgramInputChange(e, 'customerNumber')} required placeholder="Müşteri Numarası" />
                    </div>
                </div>
                <Button label="Giriş Yap" onClick={() => loginLuca()} loading={loading} disabled={handleProgramReqDisabled()} />
            </div>
            <div className="card">
                {lucaCompanies && isLoginLuca && (
                    <DataTable
                        value={lucaCompanies}
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="{totalRecords} şirketten {first} ila {last} arası gösteriliyor"
                        emptyMessage="Şirket Bulunamadı"
                        className="mb-6"
                        header={header}
                        globalFilter={globalFilter}
                    >
                        <Column field="companyName" header="Şirket İsmi" sortable style={{ minWidth: '12rem' }}></Column>
                        <Column field="periodTitle" header="Periyot" sortable style={{ minWidth: '16rem' }}></Column>
                        <Column header="İşlemler" body={actionCodeTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    </DataTable>
                )}
            </div>
            <Dialog header="Şirket Ekle" visible={dialogVisible} style={{ width: '50vw' }} maximizable modal onHide={() => setDialogVisible(false)} footer={dialogFooterTemplate}>
                <div className="mt-4">
                    {isLoginLuca && (
                        <>
                            <label className="mb-2 block">Şirketler</label>
                            <Dropdown
                                dataKey="_id"
                                value={selectedCompany}
                                onChange={handleChangeCompany}
                                options={companyList}
                                optionLabel="title"
                                placeholder="Şirket Seçiniz"
                                filter
                                className="w-full mb-4"
                                emptyMessage="Şirket Bulunamadı"
                                appendTo="self"
                            />
                            {periodList && (
                                <div className="mb-3">
                                    <label className="mb-2 block">Periyotlar</label>
                                    <Dropdown
                                        dataKey="id"
                                        value={selectedPeriod}
                                        onChange={(e) => setSelectedPeriod(e.value)}
                                        options={periodList}
                                        optionLabel="title"
                                        placeholder="Periyot Seçiniz"
                                        filter
                                        className="w-full mb-2"
                                        emptyMessage="Periyot Bulunamadı"
                                        appendTo="self"
                                    />
                                </div>
                            )}
                        </>
                    )}

                    <div className=" gap-4">
                        <div className="field flex-1">
                            <label htmlFor="eLedgerComp" className="font-bold block">
                                E Defter Şube Adı
                            </label>
                            <InputText className="w-full" id="eLedgerComp" value={eLedgerComp} onChange={(e) => setELedgerComp(e.target.value)} required autoFocus placeholder="E-Defter Şube Adı" />
                        </div>
                        <div className="field flex-1">
                            <label htmlFor="eLedgerCompCode" className="font-bold block">
                                E Defter Şube Kodu
                            </label>
                            <InputText className="w-full" id="eLedgerCompCode" value={eLedgerCompCode} onChange={(e) => setELedgerCompCode(e.target.value)} required placeholder="E-Defter Şube Kodu" />
                        </div>
                        <div className="field flex align-items-end gap-3 flex-1">
                            <div className="w-full flex flex-column gap-2">
                                <label htmlFor="taxNum" className="font-bold block">
                                    Vergi Numarası
                                </label>
                                <InputText className="w-full" id="taxNum" value={taxNum} onChange={(e) => setTaxNum(e.target.value)} required placeholder="Vergi Numarası" />
                            </div>
                            <Button onClick={() => handleGetVckn()} disabled={!taxNum} loading={loading}>
                                Getir
                            </Button>
                        </div>
                    </div>
                    <div className=" gap-4">
                        <div>
                            <div className="w-full mb-2">
                                <label>Web Servis Listesi</label>
                            </div>
                            <div>
                                <Dropdown
                                    dataKey="id"
                                    value={selectedWebService}
                                    onChange={(e) => setSelectedWebService(e.value)}
                                    options={webServicesList?.map((item) => ({ value: item.id, label: item.title })) || []}
                                    optionLabel="label"
                                    placeholder="Web Servis Seçiniz"
                                    filter
                                    className="w-full mb-3"
                                    emptyMessage="Web Servis Bulunamadı"
                                />
                            </div>
                        </div>
                        {selectedWebService && (
                            <div className="my-2">
                                <div className="w-full mb-2">
                                    <label>Web Servis Kullanıcı Adı</label>
                                </div>
                                <div className="w-full">
                                    <InputText className="w-full mb-4" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Kullanıcı Adı" required />
                                </div>
                                <div className="w-full mb-2">
                                    <label>Web Servis Şifre</label>
                                </div>
                                <div className="flex w-full align-items-center gap-3">
                                    <InputText className="w-full" type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Şifre" required />
                                    {/* <Button label="Şifreyi Kontrol Et" className="flex-shrink-0" onClick={handleCheckPortal} />*/}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="">
                        <div className="flex justify-content-between align-items-end relative">
                            <div className="w-5">
                                <label className="block mb-4 relative" style={{ bottom: '-30px' }}>
                                    Varsayılan Belge Kodu
                                </label>
                                <Dropdown
                                    dataKey="id"
                                    value={selectedCode}
                                    onChange={(e) => setSelectedCode(e.value)}
                                    options={DocumentCode.map((item) => ({ label: `${item.code} - ${item.name}`, value: item.code }))}
                                    optionLabel="label"
                                    placeholder="Varsayılan Belge Kodu Seçiniz"
                                    className="w-full"
                                    emptyMessage="Kod Bulunamadı"
                                    style={{ top: '22px' }}
                                />
                            </div>
                            <div className="w-4">
                                <label className="block relative" style={{ bottom: '-10px' }}>
                                    Yeni Belge Kodu
                                </label>
                                <InputText type="text" style={{ top: '22px' }} className="relative w-full" value={newCode} onChange={(e) => setNewCode(e.target.value)} placeholder="Yeni Belge Kodu" />
                            </div>
                            <Button label="Ekle" icon="pi pi-check" className="p-button-success text-white px-3 relative" style={{ top: '22px' }} onClick={() => handleAddCode()} disabled={!selectedCode || !newCode} />
                            <Button label="Göster" icon="pi pi-external-link px-1" className="relative" style={{ top: '22px' }} onClick={() => setDocCodeDialogVisible(true)} />
                        </div>
                    </div>
                    <div>
                        <Dialog header="Yeni Kodlar" visible={docCodeDialogVisible} style={{ width: '50vw' }} maximizable modal contentStyle={{ height: '300px' }} onHide={() => setDocCodeDialogVisible(false)} footer={dialogDocCodeFooterTemplate}>
                            <DataTable value={codeList} scrollable scrollHeight="flex" tableStyle={{ minWidth: '50rem' }} emptyMessage="Kod Bulunamadı">
                                <Column field="code" header="Varsayılan Belge Kodu"></Column>
                                <Column field="enum" header="Yeni Belge Kodu"></Column>
                                <Column header="İşlemler" body={actionDocCodeTemplate} exportable={false}></Column>
                            </DataTable>
                        </Dialog>
                    </div>
                </div>
            </Dialog>

            <Button label="Kaydet" onClick={() => handleSave()} disabled={handleAllCheckDisabled()} severity="success" className="text-white" loading={loading} />
        </div>
    );
}
