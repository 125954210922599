import axios from 'axios';
import CryptoJS from 'crypto-js';

const TOKEN = 'i-uyum-token';
const USER = 'i-uyum-user';
const SECRET_KEY = 'your-secret-key';

const encrypt = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

const decrypt = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
};

export const getToken = () => {
    const encryptedToken = localStorage.getItem(TOKEN);
    if (encryptedToken) {
        return decrypt(encryptedToken);
    }
    return null;
};

export const setToken = (token) => {
    const encryptedToken = encrypt(token);
    localStorage.setItem(TOKEN, encryptedToken);
};

export const removeToken = () => {
    localStorage.removeItem(TOKEN);
};

export const getUser = () => {
    const encryptedUser = localStorage.getItem(USER);
    if (encryptedUser) {
        return decrypt(encryptedUser);
    }
    return null;
};

export const setUser = (user) => {
    const encryptedUser = encrypt(user);
    localStorage.setItem(USER, encryptedUser);
};

export const removeUser = () => {
    localStorage.removeItem(USER);
};

export const setupAxiosInterceptors = () => {
    axios.interceptors.request.use(
        (config) => {
            const token = getToken();
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        (response) => {
            const res = response.data.data;

            if (res) {
                if (res?.user && !res?.user.yetkiler.includes('admin.login')) {
                    throw new Error('Unauthorized');
                }
            }
            
            return response;
        },
        (error) => {
            if (error.response.status === 401 || error.response.data.message === 'Unauthorized') {
                removeToken();
                removeUser();
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else if (error.response.status >= 500) {
                window.location.href = '/#/error';
            }
            return Promise.reject(error);
        }
    );
};
