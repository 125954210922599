import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';

import UserPage from './pages/user/UserPage';
import CompanyPage from './pages/company/CompanyPage.js';

import UserCompany from './pages/company/UserCompany';

import LucaPage from './pages/module/luca/LucaPage';
import UpdateLuca from './pages/module/luca/UpdateLuca';

import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import AllModule from './pages/module/AllModule';
import ParasutPage from './pages/module/parasut/ParasutPage';
import UpdateParasut from './pages/module/parasut/UpdateParasut';
import RapidPage from './pages/module/rapid/RapidPage';
import UpdateRapid from './pages/module/rapid/UpdateRapid';
import DatasoftPage from './pages/module/datasoft/DatasoftPage';
import UpdateDatasoft from './pages/module/datasoft/UpdateDatasoft';
import ZenomPage from './pages/module/zenom/ZenomPage';
import UpdateZenom from './pages/module/zenom/UpdateZenom';
import RolesPage from './pages/roles/RolesPage.js';
import RapidWaybillPage from './pages/module/rapidWaybill/RapidWaybillPage';
import UpdateRapidWaybill from './pages/module/rapidWaybill/UpdateRapidWaybill';
import Videos from './pages/videos/Videos.js';
import AllCorpAndModule from './pages/corpAndModule/AllCorpAndModule.js';
import UyumsoftComp from './pages/module/uyumsoftComp/UyumsoftComp';
import { usePermissionContext } from './context/usePermissionContext.js';
import LucaCompaniesSearch from './pages/lucaCompaniesSearch/LucaCompaniesSearch.js';
import SelectModule from './pages/tickets/SelectModule.js';
import { NotFound } from './pages/NotFound.js';
import { Error } from './pages/Error.js';

const App = () => {
    const [menuMode] = useState('overlay');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState(null);
    const copyTooltipRef = useRef();
    const location = useLocation();

    const { controlPermission } = usePermissionContext();

    const menu = [
        { label: 'Ana Sayfa', icon: 'pi pi-fw pi-home', to: '/dashboard' },
        controlPermission('users.getAll') && { label: 'Kullanıcılar', icon: 'pi pi-user', to: '/users' },
        controlPermission('company.getAll') && { label: 'Şirketler', icon: 'pi pi-briefcase', to: '/companies' },
        controlPermission('roles.getAll') && { label: 'Roller', icon: 'pi pi-id-card', to: '/roles' },
        { label: 'Şirket Ara', icon: 'pi pi-fw pi-search', to: '/searchcompany' },
        { label: 'Videolar', icon: 'pi pi-video', to: '/videos' },
        { label: 'Uyumsoft Şirketleri', icon: 'pi pi-fw pi-building', to: '/uyumsoftcompanies' },
        { label: 'Luca Şirket Ara', icon: 'pi pi-fw pi-th-large', to: '/searchLucaComp' }
    ].filter(Boolean);

    let menuClick;
    let topbarItemClick;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    const onDocumentClick = () => {
        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        topbarItemClick = false;
        menuClick = false;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onToggleMenu = (event) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onChangeActiveInlineMenu = (event) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static'
    });

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div className="layout-main">
                <AppTopbar
                    items={menu}
                    menuMode={menuMode}
                    menuActive={menuActive}
                    topbarMenuActive={topbarMenuActive}
                    activeInlineProfile={activeInlineProfile}
                    onTopbarItemClick={onTopbarItemClick}
                    onMenuButtonClick={onMenuButtonClick}
                    onSidebarMouseOver={onSidebarMouseOver}
                    onSidebarMouseLeave={onSidebarMouseLeave}
                    onToggleMenu={onToggleMenu}
                    onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                    onMenuClick={onMenuClick}
                    onMenuItemClick={onMenuItemClick}
                    onRootMenuItemClick={onRootMenuItemClick}
                    resetActiveIndex={resetActiveIndex}
                />

                <AppMenu
                    model={menu}
                    onRootMenuItemClick={onRootMenuItemClick}
                    onMenuItemClick={onMenuItemClick}
                    onToggleMenu={onToggleMenu}
                    onMenuClick={onMenuClick}
                    menuMode={menuMode}
                    menuActive={menuActive}
                    sidebarActive={sidebarActive}
                    sidebarStatic={sidebarStatic}
                    pinActive={pinActive}
                    onSidebarMouseLeave={onSidebarMouseLeave}
                    onSidebarMouseOver={onSidebarMouseOver}
                    activeInlineProfile={activeInlineProfile}
                    onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                    resetActiveIndex={resetActiveIndex}
                />

                <div className="layout-main-content">
                    <Routes>
                        <Route path="/" element={<SelectModule />} />
                        <Route path="/dashboard" element={<SelectModule />} />
                        {controlPermission('users.getAll') && <Route path="/users" index exact element={<UserPage />} />}
                        {controlPermission('company.getAll') && <Route path="/companies" index exact element={<CompanyPage />} />}
                        <Route path="/modules" index exact element={<AllModule />} />
                        {controlPermission('roles.getAll') && <Route path="/roles" element={<RolesPage />} />}
                        <Route path="/usercompany/:id" element={<UserCompany />} />
                        <Route path="/matchmodules/E-Defter/Luca" element={<LucaPage />} />
                        <Route path="/matchmodules/E-Defter/Datasoft" element={<DatasoftPage />} />
                        <Route path="/matchmodules/E-Defter/Zenom" element={<ZenomPage />} />
                        <Route path="/matchmodules/E-Fatura/Paraşüt" element={<ParasutPage />} />
                        <Route path="/matchmodules/E-Fatura/Rapid360" element={<RapidPage />} />
                        <Route path="/matchmodules/E-İrsaliye/Rapid360" element={<RapidWaybillPage />} />
                        <Route path="/matchmodulesupdate/E-Defter/Luca" element={<UpdateLuca />} />
                        <Route path="/matchmodulesupdate/E-Defter/Datasoft" element={<UpdateDatasoft />} />
                        <Route path="/matchmodulesupdate/E-Defter/Zenom" element={<UpdateZenom />} />
                        <Route path="/matchmodulesupdate/E-Fatura/Paraşüt" element={<UpdateParasut />} />
                        <Route path="/matchmodulesupdate/E-Fatura/Rapid360" element={<UpdateRapid />} />
                        <Route path="/matchmodulesupdate/E-İrsaliye/Rapid360" element={<UpdateRapidWaybill />} />
                        <Route path="/uyumsoftcompanies" element={<UyumsoftComp />} />
                        <Route path="/searchLucaComp" element={<LucaCompaniesSearch />} />
                        <Route path="/videos" element={<Videos />} />
                        <Route path="/searchcompany" element={<AllCorpAndModule />} />
                        <Route path="/error" element={<Error />} />
                        <Route path="*" element={<Navigate to="/dashboard" />} />
                    </Routes>
                </div>

                <AppFooter />
            </div>
        </div>
    );
};

export default App;
